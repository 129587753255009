import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '22, 163, 170',
		'primary-dark': '51, 51, 51',
		'accent': '50, 48, 255',
		'accent-plus': '255, 255, 255',
	},
});
